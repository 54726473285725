import React from "react";

export default function PartialDelivery() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Ebene_1"
        x="0px"
        y="0px"
        viewBox="0 0 74.7 52.4"
        style={{ enableBackground: "new 0 0 74.7 52.4" }}
      >
        <g>
          <path
            style={{ fill: "#35B6B4" }}
            d="M74.2,26.3L61.5,11.8c-0.4-0.5-1-0.7-1.6-0.7H49.3V2.2c0-1.2-1-2.2-2.2-2.2H2.2C1,0,0.1,1,0.1,2.2v2.9H0v4.4   h0.1v28.2H0V42h0.1v2.2c0,1.2,1,2.2,2.2,2.2h4.5c1,3.5,4.2,6.1,7.9,6.1c3.8,0,7-2.6,7.9-6.1h29c1,3.5,4.2,6.1,7.9,6.1   c3.8,0,7-2.6,7.9-6.1h5c1.2,0,2.2-1,2.2-2.2V27.7C74.7,27.2,74.5,26.7,74.2,26.3 M14.7,48.1c-2.1,0-3.9-1.7-3.9-3.9   c0-2.1,1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9C18.6,46.4,16.9,48.1,14.7,48.1 M45,42H22.6c-1-3.5-4.1-6.1-7.9-6.1c-3.8,0-7,2.6-7.9,6.1   H4.4V4.3H45V42z M59.6,48.1c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9C63.5,46.4,61.7,48.1,59.6,48.1    M70.4,42h-2.8c-1-3.5-4.1-6.1-7.9-6.1c-3.8,0-7,2.6-7.9,6.1h-2.3V15.5h9.6l11.5,13.1V42z"
          />
          <rect
            x="9.4"
            y="28.7"
            style={{ fill: "#35B6B4" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="20.7"
            y="28.7"
            style={{ fill: "#35B6B4" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="31.9"
            y="28.7"
            style={{ fill: "#35B6B4" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="9.4"
            y="17.5"
            style={{ fill: "#B2DCDB" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="20.7"
            y="17.5"
            style={{ fill: "#35B6B4" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="31.9"
            y="17.5"
            style={{ fill: "#35B6B4" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="9.4"
            y="6.3"
            style={{ fill: "#B2DCDB" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="20.7"
            y="6.3"
            style={{ fill: "#B2DCDB" }}
            width="9.9"
            height="9.9"
          />
          <rect
            x="31.9"
            y="6.3"
            style={{ fill: "#B2DCDB" }}
            width="9.9"
            height="9.9"
          />
        </g>
      </svg>
    </div>
  );
}
