import React, { Children } from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
const Group = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const GroupInput = styled.div`
  width: ${(props) => props.width || "300px"};
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #010e3f26;
  border: 0;
  opacity: 1;
  padding: 10px 20px;
  font-size: 18px;
  &:focus {
    outline: none;
    border: 60px;
    box-shadow: 0 0 0 0.2rem VAR(--primary-color);
  }
  @media (max-width: 1440px) {
    width: 300px;
  }
`;
const Input = styled.input`
  width: ${(props) => props.width || "300px"};
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #010e3f26;
  border: 0;
  opacity: 1;
  padding: 10px 20px;
  font-size: 18px;
  &:focus {
    outline: none;
    border: 60px;
    box-shadow: 0 0 0 0.2rem VAR(--primary-color);
  }
  @media (max-width: 1440px) {
    width: 300px;
  }
`;
const Label = styled.label`
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
const Error = styled.p`
  font-family: "Cairo", sans-serif;
  color: var(--primary-color);
`;

export default function InputGroup(props) {
  const { register } = useFormContext();
  return (
    <Container>
      <Group>
        <Label>{props.label}</Label>
        <Input {...props.input} {...register(props.name)} />
      </Group>
      {props.error ? <Error>{props.error.message}</Error> : null}
    </Container>
  );
}
export function FormGroup({label , error , children}) {
  // const { register } = useFormContext();
  return (
    <Container>
      <Group>
        <Label>{label}</Label>
        {children}
      </Group>
      {error ? <Error>{error.message}</Error> : null}
    </Container>
  );
}
