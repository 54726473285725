// import thunkMiddleware from "redux-thunk";
// import { createStore, applyMiddleware, combineReducers } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import authReducer from "./reducers/authReducer";
// import initialReducer from "./reducers/initial";
// const store = createStore(
//   combineReducers({ auth: authReducer, initial: initialReducer }),
//   composeWithDevTools(applyMiddleware(thunkMiddleware))
// );
// export default store;
import { configureStore } from "@reduxjs/toolkit";
import Auth from "./reducers/authReducer";
import initial from "./reducers/initial";
export default configureStore({
  reducer: {Auth , initial},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
}),
})