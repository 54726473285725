import { React, useState, useEffect, createContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import styles from "../assets/css/modules/shipmentReport.module.css";
import axiosInstance from "../helpers/AxiosInstance";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Header,
  Title,
  ShipmentsReportsTable,
  ButtonsContainer,
  LoadingButton,
} from "../components/utilities";
import NewPrintBtn from "../components/utilities/NewPrintBtn";
const Button = styled.button`
  position: relative;
  border: 0px solid var(--primary-color);
  border-color: var(--primary-color);
  transition: border 0.5s;
  &:hover {
    border: 2px solid var(--primary-color);
  }
  cursor: pointer;
  min-width: 142px;
  height: 50px;
  background: ${(props) => (props.primary ? "var(--gradient)" : "transparent")};
  box-shadow: ${(props) => (props.primary ? "0px 3px 6px #0000005e" : "none")};
  border-radius: 5px;
  border: 0;
  opacity: 1;
  text-align: center;
  font: normal normal 900 15px Cairo;
  letter-spacing: 0px;
  color: ${(props) => (props.primary ? "white" : "black")};
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
const StopButton = styled.button`
  position: relative;
  border: 0px solid var(--primary-color);
  border-color: var(--primary-color);
  transition: border 0.5s;
  &:hover {
    scale: 0.96;
  }
  cursor: pointer;
  width: 180px;
  height: 40px;
  background: red;
  box-shadow: 0px 3px 6px #0000005e;
  border-radius: 5px;
  border: 0;
  opacity: 1;
  text-align: center;
  font: normal normal 900 16px Cairo;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
`;

export const selectedContext = createContext();
export default function ShipmentsReport() {
  const queryClient = useQueryClient();
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [refetchKey, setRefetchKey] = useState(1);
  const [fetchingData, setFetchingData] = useState(true);

  const [mergedData, setMergedData] = useState([]);
  const [queryState, setQueryState] = useState({
    allShipments: true,
    delivered: false,
    returned: false,
    processing: false,
    paid: false,
    expected: false,
  });

  const { t } = useTranslation("reports");
  const instance = axiosInstance();
  const categories = [
    { statusID: 0, name: "allShipments" },
    { statusID: 1, name: "delivered" },
    { statusID: 2, name: "processing" },
    { statusID: 3, name: "returned" },
    { statusID: -1, name: "paid" },
    { statusID: -2, name: "expected" },
  ];
  const statusID = categories.find(
    (category) =>
      category.name ===
      Object.entries(queryState).find((entry) => entry[1] === true)?.[0]
  )?.statusID;
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const toDate = new Date();
  toDate.setDate(toDate.getDate() + 1);
  toDate.setHours(toDate.getHours() + 3);

  const fetchShipments = async (statusID) => {
    if (!fetchingData) return;
    await delay(300);
    try {
      const data = await instance.post("/GetShipmentsPage", {
        mainStatusID: statusID,
        FromDate: "2020-04-20T13:23:30.141Z",
        ToDate: toDate.toISOString(),
        pageParam: {
          pageSize: 500,
          pageNumber: refetchKey,
        },
      });

      if (data.data.shipments.length == 0) {
        setFetchingData(false);
      }
      return data.data.shipments.map((shipment) => ({
        ...shipment,
        NetCOD: shipment.COD - shipment.ShipmentFees,
        runnerData: `
          ${shipment.RunnerName}  /
          ${shipment.RunnerMobile}
          `,
      }));
    } catch (error) {
      setFetchingData(false);
      if (error.response && error.response.status === 500) {
        navigate("/server-error");
      }
    }
  };

  const { refetch } = useQuery(
    ["reports", statusID, refetchKey],
    () => fetchShipments(statusID),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000,
      staleTime: 1000,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setRefetchKey((prev) => prev + 1);
          setMergedData((prevData) => [...prevData, ...data]);
        }
      },
    }
  );

  useEffect(() => {
    if (statusID !== undefined && statusID !== null) {
      refetch();
    }
  }, [statusID, refetch]);

  const buttons = {
    allShipments: false,
    delivered: false,
    returned: false,
    processing: false,
    paid: false,
    expected: false,
  };
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("report")) {
      const status = searchParams.get("report");
      const statuses = [
        "processing",
        "returned",
        "paid",
        "expected",
        "allShipments",
        "delivered",
      ];
      if (statuses.includes(status)) {
        setQueryState({ ...buttons, [status]: true });
      }
    }
  }, [searchParams]);
  const changeUrl = (value) => {
    setSearchParams(`report=${value}`);
  };

  function changeActiveButton(e) {
    setFetchingData(true);
    queryClient.removeQueries("reports");
    setRefetchKey(1);
    let status = e.target.dataset.status;
    changeUrl(status);
    setQueryState({ ...buttons, [status]: true });
    setMergedData([]);
  }

  const handleStopButtonClick = () => {
    setFetchingData(false);
    setRefetchKey(1);
  };

  console.log(fetchingData);
  return (
    <selectedContext.Provider value={{ selected, setSelected }}>
      <Box className={styles.headerContainer}>
        <Header>
          <Title>{t("shipments_reports")}</Title>
        </Header>
        {fetchingData && (
          <StopButton onClick={handleStopButtonClick}>
            {t("stop_fetching_data")}
          </StopButton>
        )}
        {<NewPrintBtn print="shipments" data={selected} />}
      </Box>
      <Box sx={{ marginTop: "10px", width: "100%" }}>
        <ButtonsContainer disabled={fetchingData}>
          <Button
            primary={queryState.allShipments}
            data-status="allShipments"
            onClick={changeActiveButton}
          >
            {t("allShipments")}
            {queryState.allShipments && fetchingData && <LoadingButton />}
          </Button>
          <Button
            primary={queryState.delivered}
            data-status="delivered"
            onClick={changeActiveButton}
          >
            {t("delivered")}
            {queryState.delivered && fetchingData && <LoadingButton />}
          </Button>
          <Button
            primary={queryState.returned}
            data-status="returned"
            onClick={changeActiveButton}
          >
            {t("returned")}
            {queryState.returned && fetchingData && <LoadingButton />}
          </Button>
          <Button
            primary={queryState.processing}
            data-status="processing"
            onClick={changeActiveButton}
          >
            {t("processing")}
            {queryState.processing && fetchingData && <LoadingButton />}
          </Button>
          <Button
            primary={queryState.paid}
            data-status="paid"
            onClick={changeActiveButton}
          >
            {t("received_payments")}
            {queryState.paid && fetchingData && <LoadingButton />}
          </Button>
          <Button
            primary={queryState.expected}
            data-status="expected"
            onClick={changeActiveButton}
          >
            {t("expected_payments")}
            {queryState.expected && fetchingData && <LoadingButton />}
          </Button>
        </ButtonsContainer>
      </Box>
      <Box sx={{ margin: "10px", width: "100%" }}>
        <ShipmentsReportsTable rows={mergedData} />
      </Box>
    </selectedContext.Provider>
  );
}
