import styled from "styled-components";
import { Link } from "react-router-dom";
const DashboardCard = styled(Link)`
  width: 100%;
  min-width: 300px;
  height: 179px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
  box-shadow: 0px 3px 44px #681e790f;
  border-radius: 10px;
  text-decoration: none;
  opacity: 1;
  @media (max-width: 1440px) {
    transform: scale(0.8, 0.8);
  }
`;

export default DashboardCard;
