export const SET_CONFIRM = "SET_CONFIRM";
export const SET_OPEN = "SET_OPEN";
export const SET_FROM_INDEX = "SET_FROM_INDEX";
export const SET_TO_INDEX = "SET_TO_INDEX";
export const SET_PRINTED = "SET_PRINTED";
export const setConfirm = (payload) => ({
  type: SET_CONFIRM,
  payload,
});
export const setOpen = (payload) => ({
  type: SET_OPEN,
  payload,
});
export const setFromIndex = (payload) => ({
  type: SET_FROM_INDEX,
  payload,
});
export const setToIndex = (payload) => ({
  type: SET_TO_INDEX,
  payload,
});
export const setPrinted = (payload) => ({
  type: SET_PRINTED,
  payload,
});
