import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// check if loggedin
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { registerLicense } from '@syncfusion/ej2-base';

const App = React.lazy(() => import("./App"));
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBiWH9YdXFUT2RdV0c=');

const client = new QueryClient();
ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={client}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
