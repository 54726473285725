import React from "react";

export default function ArrowIcon() {
  return (
    <div>
      <svg
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="KeyboardArrowDownIcon"
        width="20px"
        fill="white"
      >
        <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
      </svg>
    </div>
  );
}
