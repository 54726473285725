import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 30px;
`;
const Text = styled.div`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const DateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px 20px;
`;
const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 80px;
  margin-top: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Line = styled.hr`
  border: 1px solid var(--primary-color);
`;

export default function Ticket({ id, time, date, statement, type, content }) {
  const { t } = useTranslation("tickets");
  return (
    <Card>
      <Header>
        <Text> {id}</Text>
        <DateContainer>
          <Text dir="ltr"> {time}</Text>
          <Text>{date}</Text>
        </DateContainer>
      </Header>
      <Line />
      <Body>
        <Text>
          {t("statement")} : {statement}
        </Text>
        <Text>
          {t("statement_type")} : {type}
        </Text>
        <Content>
          <Text>{t("content")}</Text>
          <Text style={{ color: "var(--primary-color)", fontWeight: "bold" }}>
            {content}
          </Text>
        </Content>
      </Body>
    </Card>
  );
}
