import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import styled from "styled-components";
import InputGroup from "../utilities/InputGroup";
import TextAreaInputGroup from "../utilities/TextAreaInputGroup";
import Button from "../styled/Button";

const Card = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 45%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
`;
const FormContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Form = styled.div`
  width: 70%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ExitButton = styled.button`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
`;
export default function SearchModal(props) {
  const { t } = useTranslation("multiSearch");
  const { language } = useTranslation().i18n;
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (props.value === "PickupNo") {
      setValidation({
        pickupNo: yup.string().required("برجاء ادخال رقم الطلب "),
      });
    } else if (props.value === "AWBs") {
      setValidation({
        awbs: yup.string().required("برجاء ادخال رقم الشحنة"),
      });
    } else {
      setValidation({
        refs: yup.string().required("برجاء ادخال الرقم المرجعي "),
      });
    }
  }, [props]);

  const schema = yup.object(validation).required();
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      refs: "",
      awbs: "",
      pickupNo: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const submitForm = (data) => {
    setValue("refs", "");
    setValue("awbs", "");
    setValue("pickupNo", "");

    let body = {};
    if (props.value === "PickupNo") {
      body = {
        FromDate: "2020-04-20T13:23:30.141Z",
        ToDate: new Date().toISOString(),
        pickupNo: parseInt(data.pickupNo),
      };
    } else if (props.value === "AWBs") {
      body = {
        FromDate: "2020-04-20T13:23:30.141Z",
        ToDate: new Date().toISOString(),
        awBs: data.awbs,
      };
    } else {
      body = {
        FromDate: "2020-04-20T13:23:30.141Z",
        ToDate: new Date().toISOString(),
        reFs: data.refs,
      };
    }
    props.getShipments(body);
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setValue("");
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <ExitButton
          onClick={() => {
            props.setOpen(false);
            props.setValue("");
          }}
        >
          X
        </ExitButton>
        <Title>{t("multi_search")}</Title>
        <FormProvider {...methods}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(submitForm)}>
            <div dir={language === "ar" ? "rtl" : "ltr"}>
              <FormContainer>
                <Form>
                  {props.value === "PickupNo" ? (
                    <InputGroup
                      label={t("pickup_no")}
                      input={{
                        placeholder: t("pickup_no"),
                      }}
                      name="pickupNo"
                      error={errors.pickupNo}
                    />
                  ) : null}
                  {props.value === "AWBs" ? (
                    <TextAreaInputGroup
                      label={t("awb")}
                      textArea={{
                        placeholder: t("awb"),
                      }}
                      name="awbs"
                      error={errors.awbs}
                    />
                  ) : null}
                  {props.value === "REFs" ? (
                    <TextAreaInputGroup
                      label={t("ref")}
                      textArea={{
                        placeholder: t("ref"),
                      }}
                      name="refs"
                      error={errors.refs}
                    />
                  ) : null}
                  <Button>{t("search")}</Button>
                </Form>
              </FormContainer>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
}
