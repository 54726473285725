import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { InputBase, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "../../assets/css/modules/SelectInputGroup.module.css";

const InputStyle = styled(InputBase)(({ theme }) => ({
  " .MuiInputBase-input": {
    position: "relative",
    border: 0,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 6px #010E3F26",
    fontSize: 18,
    padding: "16px 27px 16px 16px",
    "&:focus": {
      border: 0,
      boxShadow: "0 0 0 0.2rem var(--primary-color)",
    },
  },
}));

export default function SelectInputGroup(props) {
  const { itemName, itemValue } = props;
  const { control } = useFormContext();
  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <label className={styles.label}>{props.label}</label>
        <Controller
          name={props.name}
          control={control}
          rules={
            props.applyRules ? { required: `${props.errorMassage}` } : null
          }
          render={({ field }) => (
            <Select
              disabled={props.disabled}
              {...field}
              displayEmpty
              sx={{ width: "300px", minWidth: "300px" }}
              input={<InputStyle />}
            >
              <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                {props.placeholder}
              </MenuItem>
              {props.items.map((item, index) => (
                <MenuItem
                  key={item[itemName]}
                  value={itemValue ? item[itemValue] : index + 1}
                >
                  {item[itemName]}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
      {props.error ? (
        <p className={styles.error}>{props.error.message}</p>
      ) : null}
    </div>
  );
}
