import React, { useState, useEffect } from "react";
import { Box, MenuItem, Menu, useMediaQuery, IconButton } from "@mui/material";
import styles from "../../assets/css/modules/navbar.module.css";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleLogout } from "../../redux/reducers/authReducer";
import styled from "styled-components";
import Searchbar from "./Searchbar";
import MobileLayout from "./MobileLayout";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axiosInstance from "../../helpers/AxiosInstance";
import Popover from "@mui/material/Popover";
import { ButtonsContainer, Notification } from "../utilities";
import Translation from "../utilities/Translation";
import { useLocation } from "react-router-dom";
const NavbarBtn = styled(Link)`
  color: white;
  background: var(--gradient);
  font-size: 9pt;
  font-weight: 500;
  display: grid;
  place-items: center;
  text-decoration: none;
  border-radius: 5px;
  width: 10%;
  min-width: 100px;
  height: 35px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  min-width: 300px;
  height: 480px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  @media (max-width: 1440px) {
    width: 400px;
  }
  @media (max-width: 425px) {
    min-width: 300px;
  }
`;

const Line = styled.hr`
  border: 1px solid var(--primary-color);
`;

const Button = styled(Link)`
  min-width: 162px;
  height: 50px;
  background: transparent;
  display: grid;
  place-items: center;
  text-align: center;
  font-weight: 700;
  font-family: Cairo;
  font-size: 18px;
  letter-spacing: 0px;
  color: #010e3f;
  text-decoration: none;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;

export default function Navbar({ sideOpen, RoleID }) {
  const { pathname } = useLocation();
  const { t } = useTranslation("layout");
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchor, setAnchor] = useState(null);
  const openNotification = Boolean(anchor);
  const openTranslation = Boolean(anchor);
  const handleNotificationClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const [notifications, setNotifications] = useState([]);
  const instance = axiosInstance();
  const { data } = useQuery(
    "notifications",
    async () => {
      const { data } = await instance.get("/GetActiveNotifications");
      return data;
    },
    {
      staleTime: 60000,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setNotifications(data.notifications);
    }
  }, [data]);

  const { CompanyName, Logo } = useSelector((state) => ({
    CompanyName: state.Auth.companyData.CompanyName,
    Logo:
      state.Auth.companyData.Logo ||
      `https://ahmedaglan.github.io/ClientsJSON/ClientImages/${
        state.Auth.companyData.ClientID || "999999"
      }/logo.png`,
  }));
  const { ClientName } = useSelector((state) => ({
    ClientName: state.Auth.AuthedUser.ClientName,
  }));
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    dispatch(handleLogout());
  };
  if (matches) {
    return <MobileLayout RoleID={RoleID} />;
  }
  return (
    <div className={styles.navbar}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: { sm: "flex", xs: "flex", md: "none", lg: "none" },
              cursor: "pointer",
            }}
          >
            extend
          </Box>
          <Box
            onClick={handleClick}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="9"
              viewBox="0 0 20 9"
            >
              <defs>
                <linearGradient
                  id="a"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="var(--primary-color)" />
                  <stop offset="1" stopColor="var(--secondary-color)" />
                </linearGradient>
              </defs>
              <path
                d="M9.331.6a1,1,0,0,1,1.338,0l7.394,6.655A1,1,0,0,1,17.394,9H2.606a1,1,0,0,1-.669-1.743Z"
                transform="translate(20 9) rotate(180)"
                fill="url(#a)"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              transform="scale(.6)"
              height="50"
              viewBox="0 0 50 50"
            >
              <defs>
                <linearGradient
                  id="a"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="var(--primary-color)" />
                  <stop offset="1" stopColor="var(--secondary-color)" />
                </linearGradient>
              </defs>
              <g transform="translate(-1276 -24)">
                <rect
                  width="50"
                  height="50"
                  rx="5"
                  transform="translate(1276 24)"
                  fill="url(#a)"
                />
                <path
                  d="M28.024,26.218a.764.764,0,0,1-.369.6,3.122,3.122,0,0,1-1.136.451q-.767.177-1.574.328a17.9,17.9,0,0,1-2.066.233q-1.259.082-2.19.123t-2.395.069q-1.464.027-2.217.027H11.945l-2.217-.027-2.395-.069-2.19-.123L3.078,27.6,1.5,27.272.369,26.82,0,26.218q.055-2.409,3.01-4.255a18.693,18.693,0,0,1,7.5-2.45v-.9a7.233,7.233,0,0,1-2.463-1.779,8.64,8.64,0,0,1-1.642-2.7,15.805,15.805,0,0,1-.876-3.312,24.9,24.9,0,0,1-.274-3.817,6.883,6.883,0,0,1,.684-3.12,5.764,5.764,0,0,1,1.888-2.2A8.919,8.919,0,0,1,10.59.424,12.981,12.981,0,0,1,14.012,0a13.059,13.059,0,0,1,3.421.424A8.882,8.882,0,0,1,20.2,1.683a5.771,5.771,0,0,1,1.888,2.2,6.891,6.891,0,0,1,.684,3.12q0,9.579-5.254,11.659v.849a18.684,18.684,0,0,1,7.5,2.449Q27.968,23.809,28.024,26.218Z"
                  transform="translate(1287 35) "
                  fill="#fff"
                />
              </g>
            </svg>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={handleLogoutClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "7px",
              }}
            >
              <Box sx={{ fontSize: ".70em" }}>{t("Navbar.signout")}</Box>
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "7px",
              }}
              component={NavLink}
              to="/change-password"
            >
              <Box sx={{ fontSize: ".70em" }}>
                {t("Navbar.change_password")}
              </Box>
            </MenuItem>
          </Menu>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {RoleID === 2 && (
            <IconButton
              style={{ background: "transparent" }}
              onClick={handleNotificationClick}
              sx={{
                background: "transparent",
                position: "relative",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  width: "15px",
                  borderRadius: "50%",
                  height: "15px",
                  backgroundColor: data?.notifications.length
                    ? "var(--primary-color)"
                    : "transparent",
                },
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 29.255 33"
              >
                <path
                  d="M32.288,23.088,29.25,20.015V14.78A11.412,11.412,0,0,0,19.125,3.4V1.125h-2.25V3.4A11.5,11.5,0,0,0,6.75,14.78v5.234L3.712,23.088a1.041,1.041,0,0,0-.337.8V27.3a1.076,1.076,0,0,0,.307.827,1.05,1.05,0,0,0,.818.311h7.875a5.625,5.625,0,1,0,11.25,0H31.5a1.05,1.05,0,0,0,.818-.311,1.076,1.076,0,0,0,.307-.827V23.884A1.041,1.041,0,0,0,32.288,23.088ZM18,31.849a3.4,3.4,0,0,1-3.375-3.414h6.75A3.4,3.4,0,0,1,18,31.849Z"
                  transform="translate(-3.373 -1.125)"
                  fill="var(--primary-color)"
                />
              </svg>
            </IconButton>
          )}
          {/* ======================================================================================== */}
          <Translation />
          <Box>
            {!pathname.includes("cshome") && <Searchbar RoleID={RoleID} />}
          </Box>
        </Box>
        {RoleID === 2 && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <NavbarBtn to="/new-shipment">
                {" "}
                {t("Navbar.add_shipment")}
              </NavbarBtn>
              <NavbarBtn to="/new-pickup"> {t("Navbar.add_pickup")}</NavbarBtn>
              <NavbarBtn to="/upload"> {t("Navbar.upload_excel")} </NavbarBtn>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    flexDirection: "column",
                    "@media (max-width: 1060px)": {
                      display: "none",
                    },
                  }}
                >
                  <Box sx={{ fontWeight: 700 }}>{ClientName}</Box>
                </Box>
                <Box
                  sx={{
                    minWidth: "50px",
                    height: "50px",
                    fontWeight: "400",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "50%",
                    color: "white",
                    padding: "5px",
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "40px",
                      height: "40px",
                      top: "2px",
                      position: "relative",
                      "@media (max-width: 965px)": {
                        display: "none",
                      },
                    }}
                    src={Logo}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* ============================================================= */}
      <Popover
        open={openNotification}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        onClick={() => {
          setAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ fontFamily: "Cairo" }}
      >
        <Card>
          <div dir="rtl">
            {notifications.slice(0, 3).map((notification, index) => (
              <Notification
                key={index}
                setNotifications={setNotifications}
                notifications={notifications}
                notificationIndex={index}
                AWB={notification.AWB}
                Body={notification.Body}
                Status={notification.StatusName}
                Serial={notification.Serial}
              />
            ))}
            <Line />
            {notifications.length ? (
              <ButtonsContainer
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Button to="/notifications" onClick={() => setAnchor(null)}>
                  {t("Navbar.all_notifications")}
                </Button>
              </ButtonsContainer>
            ) : (
              <ButtonsContainer
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "18px",
                }}
              >
                {t("Navbar.no_Notification")}
              </ButtonsContainer>
            )}
          </div>
        </Card>
      </Popover>
    </div>
  );
}
