import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import axiosInstance from "../helpers/AxiosInstance";
import { useState } from "react";
import fileDownload from "js-file-download";
import { Button } from "./utilities";
import { useTranslation } from "react-i18next";
const Card = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 3rem;
  height: 80vh;
  max-width: 80vw;

  justify-content: center;
`;
const LabelImage = styled.img`
  position: relative;
  height: 90%;
  width: 100%;
  object-fit: cover;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
`;
export default function LabelPreview(props) {
  const { t } = useTranslation("uploadExcel");
  const { open, setOpen, label, data } = props;
  const [loading, setLoading] = useState(false);
  const instance = axiosInstance();
  let craftedData = {
    awBs: [],
  };
  if (typeof data === "number") {
    craftedData.pickupNo = data;
    craftedData.awBs = [];
  } else {
    craftedData.awBs = typeof data === "string" ? [data] : data;
    delete craftedData.pickupNo;
  }
  const handlePrintPdf = async () => {
    setLoading(true);
    const response = await instance
      .post(
        "ExportPDF",
        {
          templateID: label.templateID,
          ...craftedData,
        },
        { responseType: "blob" }
      )
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
    setOpen(false);
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const hour = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const milliSec = new Date().getMilliseconds();
    fileDownload(
      response.data,
      `${label.name} - ${day}/${month}/${year} ${hour}:${minutes}:${seconds}:${milliSec}.pdf`
    );
  };
  const handlePreviewPdf = async () => {
    setLoading(true);
    const response = await instance
      .post(
        "ExportPDF",
        {
          templateID: label.templateID,
          ...craftedData,
        },
        { responseType: "blob" }
      )
      .then((res) => {
        setLoading(true);
        setOpen(false);
        const url = URL.createObjectURL(res.data);
        setLoading(false);
        const newWindow = window.open(url);
        // await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for the new window to load
        newWindow.open();
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <div>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: "111111",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          open={loading}
        >
          <CircularProgress
            size={80}
            color="primary"
            sx={{ marginBlock: "20px" }}
          />
          <Typography variant="h4">{t("loading_msg")}</Typography>
        </Backdrop>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <ExitButton onClick={() => setOpen(false)}>X</ExitButton>
          {label ? (
            <>
              <LabelImage src={`/labels/${label.name}.png`} alt="Label Image" />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "25px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button onClick={handlePrintPdf} disabled={loading}>
                  {loading ? <CircularProgress /> : t("export_pdf")}
                </Button>
                <Button disabled={loading} onClick={handlePreviewPdf}>
                  {loading ? <CircularProgress /> : t("preview_pdf")}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <h3>{t("no_recorded_img")}</h3>
              <LabelImage
                src={`/labels/${label?.name}.png`}
                alt="image not found"
              />
            </>
          )}
        </Card>
      </Modal>
    </>
  );
}
