import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { SubmitBtn, Label, TextInput, CustomBtn } from "../styled/auth";
import styled from "styled-components";
import { handleUserLogin } from "../../redux/reducers/authReducer";
import { unwrapResult } from "@reduxjs/toolkit";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledImage = styled.img`
  width: 60px;
  height: 60px;
`;

export default function UserLogin(props) {
  const [error, setError] = useState("");
  const { setLoading, setStep } = props;
  const { companyData } = useSelector((state) => ({
    companyData: state.Auth.companyData,
  }));
  const dispatch = useDispatch();
  const { t } = useTranslation("auth");
  const handleStepBack = () => {
    setStep(1);
  };
  const handleClose = () => {
    setError(false);
  };
  const submitForm = (data) => {
    dispatch(
      handleUserLogin({
        data: data,
        setLoading: setLoading,
      })
    )
      .then(unwrapResult)
      .catch((err) => {
        setLoading(false);
        setError("UserLogin", { message: t("Login.UserName_Error") });
      });
  };
  const schema = yup
    .object({
      username: yup.string().required(t("Login.UserName_Error")),
      password: yup.string().required(t("Login.Password_Error")),
    })
    .required();
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 1)",

              display: "flex",
              flexDirection: "column",
              margin: {
                xs: "30px auto",
                sm: "25px auto",
                md: "15px auto",
                lg: "0px",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "35px",
              minWidth: {
                xs: "300px",
                sm: "500px",
                md: "500px",
                lg: "50vw",
              },
              minHeight: {
                xs: "400px",
                sm: "400px",
                md: "400px",
                lg: "90vh",
              },
              borderRadius: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              padding: {
                xs: "16px",
                sm: "16px",
                md: "16px 12px",
                lg: "16px 8px",
              },
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                  margin: "0 auto",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                <StyledImage
                  src={
                    companyData.Logo ||
                    `https://ahmedaglan.github.io/ClientsJSON/ClientImages/${
                      companyData.ClientID || "999999"
                    }/logo.png`
                  }
                />
              </Box>
              <Box>
                {t("Login.welcome", { company: companyData.CompanyName })}
              </Box>
              <Box component="h3">{t("Login.Login_Header")}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { md: "center", lg: "unset" },
                width: {
                  sm: "100%",
                  md: "100%",
                  lg: "75%",
                  xl: "65%",
                },
                gap: "20px",
              }}
            >
              {error && (
                <Snackbar
                  open={error}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ px: 2 }}>{t("Login.error_message")}</Box>
                  </Alert>
                </Snackbar>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxWidth: { md: "300px", lg: "unset" },
                }}
              >
                <Label htmlFor="username">{t("Login.UserName_Label")}</Label>

                <TextInput
                  id="username"
                  name="username"
                  disableUnderline
                  placeholder={`${t("Login.UserName_Placeholder")}`}
                  {...register("username")}
                />
                {errors.username && (
                  <Box sx={{ color: "red" }}>{errors.username.message}</Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Label htmlFor="password">{t("Login.Password_Label")}</Label>
                <TextInput
                  id="password"
                  type="password"
                  name="password"
                  {...register("password")}
                  disableUnderline
                  placeholder={`${t("Login.Password_Placeholder")}`}
                />
                {errors.password && (
                  <Box sx={{ color: "red" }}>{errors.password.message}</Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <SubmitBtn type="submit">{t("Login.Login_Submit")}</SubmitBtn>
            </Box>
            {!companyData.defined && (
              <>
                <Box component="h4" sx={{ textAlign: "center" }}>
                  {t("Login.other_header")}
                </Box>
                <Box
                  sx={{
                    margin: "0 auto",
                    display: "flex",
                    columnGap: "35px",
                    rowGap: "20px",
                    maxWidth: "650px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <CustomBtn onClick={handleStepBack}>
                    {t("Login.step_back")}
                  </CustomBtn>
                </Box>
              </>
            )}
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
