import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import printSelected from "../../helpers/labelPrint/index";
import { MenuItem, Select, FormControl, InputBase } from "@mui/material";
import { selectedContext } from "../../pages/ShipmentsReport";
import ConfirmationDialog from "../ConfirmationDialog";
import DeprecationDialog from "../DeprecationDialog";
const StyledSelect = styled(InputBase)(() => ({
  " .MuiInputBase-input": {
    position: "relative",
    border: "1px solid var(--primary-color)",
    fontSize: 13,
    paddingBlock: "12px",
    paddingLeft: "10px",
    width: "117px",
    fontWeight: "bold",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #0000005E",
  },
  ".MuiSelect-iconOutlined": {
    color: "var(--primary-color)",
  },
}));
export default function PrintBtn({ data, print, setLoading, setError }) {
  let selected = useContext(selectedContext)?.selected;
  let selectedData = data ? data : selected;
  const [open, setOpen] = useState(false);
  const [args, setArgs] = useState([]);
  setLoading = setLoading ? setLoading : () => {};
  const { t } = useTranslation("shipmentDetail");
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    if (data.length > 0 || data.toString().length > 0) {
      setOpen(true);
      setArgs([event.target.value, selectedData, setError]);
      setValue("");
    }
    // do the export action
  };
  return (
    <div>
      <ConfirmationDialog id="ringtone-menu" keepMounted />
      <FormControl>
        <Select
          input={<StyledSelect defaultValue={""} />}
          value={value}
          id="export"
          onChange={handleChange}
          displayEmpty
          disableunderline="true"
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            <em>
              {print === "shipments"
                ? t("print.defaultValue")
                : t("print.pickupValue")}
            </em>
          </MenuItem>
          <MenuItem value="style1">{t("print.style1")}</MenuItem>
          <MenuItem value="style2">{t("print.style2")}</MenuItem>
          {/* <MenuItem value="style3">{t("print.style3")}</MenuItem> */}
        </Select>
      </FormControl>
      <DeprecationDialog
        open={open}
        setOpen={setOpen}
        callback={{ fn: printSelected, args }}
      />
    </div>
  );
}
